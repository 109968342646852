import React from 'react';

const Cover = () => {
  return (
    <div className="cover">
      <h1>Hi Everybody!</h1>
      <p>Welcome to my website. Have a look around and get to know more about me.</p>
    </div>
  );
}

export default Cover;